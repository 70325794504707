.dashboardHello-wrapper{
    width: 100%;
    height: 150px;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 11px -8px #000000;
  box-shadow: 0px 0px 11px -8px #000000;
  background-color:#ffff;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}