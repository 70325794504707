.myCurrencies-wrapper {
    height: 700px;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
  }
.myCurrencies-wrapper2 {
    width: 45%;
    height: 600px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 11px -8px #000000;
    box-shadow: 0px 0px 11px -8px #000000;
    background-color:#ffff;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
.myCurrencies-containers-wrapper{
    height: 90%;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}
.myCurrencies-showRates-container{
    height: 20%;
    width: 100%;
    -webkit-box-shadow: 0px 0px 11px -8px #000000;
  box-shadow: 0px 0px 11px -8px #000000;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.myCurrencies-input-wrapper{
    width: 30%;
}
.myCurrencies-input{
    height: 50%;
    width: 80%;
    border: none;
    border-bottom: #000000 solid 1px;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size:large;
    outline: none;
}
.myCurrencies-button{
    position: relative;
    height: 10%;
    width: 100%;
    border: none;
    border-radius: 35px;
    font-family: 'Montserrat';
    font-weight: bold;
    background: rgb(33,149,243);
background: linear-gradient(90deg, rgba(33,149,243,1) 35%, rgba(174,236,255,1) 100%);
    color: #ffff;
    font-size: large;
}
.myCurrencies-button:hover{
    -webkit-box-shadow: 0px 0px 15px -5px #000000;
    box-shadow: 0px 0px 15px -5px #000000;
}
.myCurrencies-button:active{
    background: rgb(33,149,243);
background: linear-gradient(90deg, rgb(26, 124, 205) 35%, rgb(136, 195, 213) 100%);
}

.myCurrencies-button::after{
    content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background:rgb(255, 255, 255) url("./gif/loader.gif") center/50px no-repeat;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
}
.myCurrencies-button._sendingFile::after{
    opacity: 1;
 visibility: visible;
}
@media (max-width: 1000px) {
    .myCurrencies{
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        justify-content: space-around;
        font-family: 'Montserrat', sans-serif;
    }
    .myCurrencies-wrapper2 {
        width: 100%;
        height: 600px;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 11px -8px #000000;
        box-shadow: 0px 0px 11px -8px #000000;
        background-color:#ffff;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
}