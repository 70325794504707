@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;900&display=swap');
.currency-wrapper {
  height: 400px;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}
.currency-wrapper2 {
  width: 45%;
  height: 300px;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 11px -8px #000000;
  box-shadow: 0px 0px 11px -8px #000000;
  background-color:#ffff;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.currency-title{
    margin: 0;
    font-weight:500;
}
.currency-containers-wrapper{
    height: 80%;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
}
.currency-showRates-container{
    height: 40%;
    width: 100%;
    -webkit-box-shadow: 0px 0px 11px -8px #000000;
  box-shadow: 0px 0px 11px -8px #000000;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.currency-convert-container{
    height: 40%;
    width: 100%;
    -webkit-box-shadow: 0px 0px 11px -8px #000000;
    box-shadow: 0px 0px 11px -8px #000000;
      border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.currency-currency-container{
    width:25%;
    display: flex;
    justify-content: space-between;
}
.currency-showRates-flag{
    width: 40px;

}
.currency-convert-input{
    height: 50%;
    width: 30%;
    border: none;
    border-bottom: #000000 solid 1px;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size:large;
    outline: none;
}
.currency-convert-button{
    height: 30%;
    width: 100%;
    border: none;
    border-radius: 35px;
    font-family: 'Montserrat';
    font-weight: bold;
    background: rgb(33,149,243);
background: linear-gradient(90deg, rgba(33,149,243,1) 35%, rgba(174,236,255,1) 100%);
    color: #ffff;
    font-size: large;

}
.currency-convert-button:hover{
    -webkit-box-shadow: 0px 0px 15px -5px #000000;
    box-shadow: 0px 0px 15px -5px #000000;
}
.currency-convert-button:active{
    background: rgb(33,149,243);
background: linear-gradient(90deg, rgb(26, 124, 205) 35%, rgb(136, 195, 213) 100%);
}
@media (max-width: 1000px) {
    .currency-wrapper{
        display: flex;
        flex-direction: column;
        height: 650px;
        width: 100%;
        justify-content: space-around;
        font-family: 'Montserrat', sans-serif;
    }
    .currency-wrapper2 {
        width: 100%;
        height: 300px;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 11px -8px #000000;
        box-shadow: 0px 0px 11px -8px #000000;
        background-color:#ffff;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }
}